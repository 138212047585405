import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../../assets/icons';
import { Environment, getCurrentApiEnv } from '../../config/Environment';
import { URL_ZENDESK } from '../../constants';
import { RouteUrl } from '../../enum';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { theme } from '../../styles/theme';
import { logoutFunction } from '../../utils/logout';
import LinkExternal from '../LinkExternal';
import { Container, LiSidebar, UlSub } from './styled';

export type LeftSidebarProps = {
  active: boolean;
  setLeftSidebar: () => void;
};

const { resources } = Environment.baseUrl[getCurrentApiEnv()];

const itensMenu = [
  {
    name: 'Início',
    icon: 'home',
    url: '/',
  },
  {
    name: 'Simular Entrega',
    icon: 'box',
    url: '/simulate-delivery',
    children: [
      {
        name: 'Simulação em lote',
        url: RouteUrl.SimulateBatch,
        disable: !resources.simulateBatch,
      },
    ],
  },
  {
    name: 'Meus Envios',
    icon: 'truck',
    url: '/orders-cart',
  },
  // {
  //   name: 'Meus Envios',
  //   icon: 'truck',
  //   url: '/requests',
  // },
  {
    name: 'Carteira',
    icon: 'wallet',
    url: '/wallet',
  },
  {
    name: 'Integrações',
    icon: 'puzzle-piece',
    url: '/integrations',
    children: [
      {
        name: 'Pedidos Integrados',
        url: RouteUrl.PreOrders,
      },
    ],
    disable: !resources.integration,
  },
  {
    name: 'Pontos de Envio',
    icon: 'map-market',
    url: RouteUrl.ShippingPoint,
  },
  {
    name: 'Meu Perfil',
    icon: 'user',
    children: [
      {
        name: 'Meu Cadastro',
        url: '/profile',
      },
      {
        name: 'Embalagem Padrão',
        url: RouteUrl.DefaultPackage,
      },
      {
        name: 'Cadastro de Clientes',
        url: RouteUrl.ShopperRegistration,
      },
    ],
  },
  {
    name: 'FAQ',
    icon: 'question-comment',
    url: RouteUrl.FAQ,
  },
  {
    name: 'Sair',
    icon: 'sign-out-alt',
    url: '/logout',
  },
];

function LeftSidebar({
  active,
  setLeftSidebar,
}: LeftSidebarProps): ReactElement {
  const location = useLocation();
  const { width } = useWindowDimensions();
  const { logout } = useAuth0();
  const onLogout = () => {
    logoutFunction();
    logout({ returnTo: window.location.origin });
  };

  const getIcon = (name: string, isActive: boolean): ReactElement => {
    return (
      <Icon
        name={name}
        color={
          isActive ? theme.colors.secondaryColor : theme.colors.primaryColor
        }
      />
    );
  };

  const setSidebar = (): void => {
    if (width <= 1023) {
      setLeftSidebar();
    }
  };

  return (
    <Container active={active} setLeftSidebar={setLeftSidebar}>
      <ul>
        {itensMenu.map(
          (item, i) =>
            !item.disable && (
              <LiSidebar
                key={item.name}
                style={i === 0 ? { paddingTop: '9px' } : {}}
              >
                {item.children ? (
                  item.url ? (
                    <Link
                      to={item.url || '#'}
                      className={
                        item.url === location.pathname ? ' active-nav' : ''
                      }
                      onClick={() => setSidebar()}
                    >
                      {item.icon &&
                        getIcon(
                          item.icon,
                          !!(
                            item.children.find(
                              x => x.url === location.pathname,
                            ) || item.url === location.pathname
                          ),
                        )}
                      {item.name}
                    </Link>
                  ) : (
                    <span
                      className={
                        item.children.find(x => x.url === location.pathname)
                          ? ' active-nav'
                          : ''
                      }
                    >
                      {item.icon &&
                        getIcon(
                          item.icon,
                          !!item.children.find(
                            x => x.url === location.pathname,
                          ),
                        )}
                      {item.name}
                    </span>
                  )
                ) : item.url === '/logout' ? (
                  <Link
                    to={item.url || '#'}
                    className={`${
                      item.url === location.pathname ? ' active-nav' : ''
                    }`}
                    onClick={() => onLogout()}
                  >
                    {item.icon &&
                      getIcon(item.icon, item.url === location.pathname)}
                    {item.name}
                  </Link>
                ) : (
                  <Link
                    to={item.url || '#'}
                    className={`${
                      item.url === location.pathname ? ' active-nav' : ''
                    }`}
                    onClick={() => setSidebar()}
                  >
                    {item.icon &&
                      getIcon(item.icon, item.url === location.pathname)}
                    {item.name}
                  </Link>
                )}
                {item.children?.length && (
                  <UlSub>
                    {item.children.map(
                      (child: any) =>
                        !child.disable && (
                          <li key={item.name + child.name}>
                            <Link
                              to={child.url || '/'}
                              className={`${
                                child.url === location.pathname
                                  ? ' active-nav'
                                  : ''
                              }`}
                              onClick={() => setSidebar()}
                            >
                              - {child.name}
                            </Link>
                          </li>
                        ),
                    )}
                  </UlSub>
                )}
              </LiSidebar>
            ),
        )}
      </ul>
    </Container>
  );
}

export default LeftSidebar;

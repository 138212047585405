import { AxiosResponse } from 'axios';

import { GetWalletResponse, PostDepositResponse } from './wallet.types';
import HttpClientPayments from '../../config/HttpClientPayments';

export class WalletService {
  api: HttpClientPayments;

  constructor() {
    this.api = new HttpClientPayments();
  }

  getWallet = (
    signal?: AbortSignal,
  ): Promise<AxiosResponse<GetWalletResponse>> => {
    return this.api.get(`v1/frenet/wallet`, signal);
  };

  postWalletDeposit = (
    value: number,
  ): Promise<AxiosResponse<PostDepositResponse>> => {
    return this.api.post(`v1/frenet/wallet/deposit`, { value });
  };
}
